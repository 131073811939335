// colors
$white: #ffffff;
$slate-grey: #5f6772;
$pale-grey: #e7d7ee;
$pale-grey-two: #ede1f2;
$white-two: #e8e8e8;
$white-three: #eeeeee;
$white-four: #95989a;
$grey: #9f9f9f;
$violet-darker: #5A4591;
$violet-dark:   #8D3EAF;
$violet:        #CF16BD;
$violet-medium: #BE84DD;
$violet-bright: #E7D7EE;
$red:           #FF3D00;

$light-lines: #ab91ed;

// responsive contexts
$screen-phone-max: 767px;
$screen-tablet: 768px;
$screen-tablet-max: 991px;
$screen-notebook: 992px;
$screen-notebook-max: 1199px;
$screen-desktop: 1200px;
$screen-desktop-max: 1399px;
$screen-desktop-large: 1400px;

// heights
$nav-height-phone: 182px;
$nav-height-phone-home: 182px;
$nav-height-tablet: 186px;
$nav-height-notebook: 82px;
$footer-social-line-tablet: 105px;
$footer-social-line-notebook: 65px;
$footer-imprint-line-tablet: 57px;
$footer-imprint-line-notebook: 33px;