main {

  &.blur {
    @media all and (max-width: $screen-tablet-max) {
      @include blur();
    }
  }

  &.selection {

    .selection-empty {
      min-height: 80vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h3{
        @include h3(0, 10px, $slate-grey);
      }
      p {
        @include p(0, 10px);
      }
      img {
        width: 320px;
        padding: 10px;
        @media all and (min-width: $screen-tablet) {
          width: 500px;
        }

        @media all and (min-width: $screen-notebook) {
          padding: 40px 20px;
          width: 600px;
        }
      }
      &.hidden {
        display: none;
      }
    }
  }
}

#show-hide-menu {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

#selection-button-list {
  display: block !important;
  &.container {
    @media all and (max-width: $screen-phone-max) {
      //padding: 0 20px;
    }
    @media all and (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
      padding: 0 30px 30px 30px;
    }
    @media all and (min-width: $screen-notebook) and (max-width: $screen-notebook-max) {
      //padding: 0 30px 30px 30px;
    }
  }
}

#selection-button-rows { display: none !important; }

@media all and (min-width: $screen-notebook) {
  #show-hide-menu, #selection-button-list { display: none !important; }
  #selection-button-rows { display: block !important; }
}
