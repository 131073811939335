header {

  padding: 30px 0;
  background-color: $violet-bright;

  h1 {
    @include h1(0, 0 0 12px 0);
  }

  p {
    font-size: 20px;
    line-height: 26px;
    color: $violet-darker;
    padding: 0;
    margin: 0;
  }

}