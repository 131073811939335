.revenue-grid {

  @media (min-width: $screen-phone-max) {
    display:grid;
    grid-column-gap: 17px;
    grid-row-gap: 27px;

    :nth-child(1) {
      grid-area: box1;
    }
    :nth-child(2) {
      grid-area: box2;
    }
    :nth-child(3) {
      grid-area: box3;
    }
    :nth-child(4) {
      grid-area: table;
    }
    :nth-child(5) {
      grid-area: box4;
      align-self:start;
    }
  }

  @media (max-width: $screen-phone-max) {
    div + div {
      margin-top:25px;
    }
  }

  @media (min-width: $screen-notebook) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "box1 box2 box3"
      "table table box4";
  }

  @media (min-width: $screen-tablet) and (max-width:$screen-tablet-max) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "box1 box2"
      "box3 box4"
      "table table";
  }

}