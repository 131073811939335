.form-card {

  @include card();
  margin:35px 10px;
  padding:40px 20px;
  background:white;

  h3 {
    margin-bottom:20px;
  }

  b {
    display: inline-block;
    margin-bottom:10px;
  }

  p {
    @include p();
    line-height: 22px;
  }

  select, input, p, textarea {
    max-width:85%;
  }

  a {
    display:inline-block;
    margin-top:15px;
    margin-bottom:60px;
  }
}


label {
  // Allow labels to use `margin` for spacing.
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}

.form-control {
  display: block;
  min-width: 75px;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 16px;
  color: $slate-grey;
  background-color: #e8e8e8;
  background-clip: padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 50px;

  // @include box-shadow($input-box-shadow);
  // @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    color: #cf16bd;
    background-color: white;
    border-color: rgba(207, 22, 189, 0.24);
    outline: 0;
    box-shadow: 0 0 10px 0 rgba(207, 22, 189, 0.24);
  }

  // Placeholder
  &::placeholder {
    color: #c0c0c0;
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: white;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

textarea.form-control {
  border-radius: 10px;
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $slate-grey;
    background-color: #e8e8e8;
  }
}

.form-group {
  margin-bottom: 1rem;
}

.is-invalid {
  border-color: red;
  &:focus {
    border-color: red;
    box-shadow: 0 0 0 2px rgba(red, .25);
  }
}

.form-group-columns {
  @media (min-width: $screen-tablet) {
    display:flex;
  }
  .form-group {
    flex:100% 0 1;
  }
  .form-group-column-75 {
    flex:75% 0 0;
    padding-right:15px;
  }
  .form-group-column-50 {
    flex:50% 0 0;
    padding-right:15px;
  }
  .form-group-column-30 {
    flex:30% 0 0;
    padding-right:15px;
  }
}
