.selection-items {
  .item-title {
    @include h5(0, 0, $violet-dark);
    font-size: 18px;
    font-weight: bold;
    background-color: $violet-bright;
    text-align: left;
    line-height: 1.15;
    border-radius: 20px 20px 0 0;
    padding:18px 95px 18px 21px;
  }

  .selection-item {
    display:block;

    .item-img-container {
      background: $white-three;
      border-radius: 0 0 20px 20px;
      img {
        width:100%;
        display:block;
        border-radius: 0 0 20px 20px;
      }
    }
  }

  .item-top {
    position:relative;
  }

  .no-selection {
    display:block;
    position:relative;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

[data-type="brand"], [data-type="model"], .no-selection {
  text-decoration: none;
  .underline {
    text-decoration: underline;
  }
  .item-title {
    border-radius:20px;
    min-height: 40px;
    line-height: 24px;
    padding: 10px 95px 10px 21px;
  }
}

[data-type="brand"] {
  .item-title {
    text-align:center;
    padding: 8px 0;
  }
  img {
    display:block;
    max-height: 80px;
    object-fit: contain;
    width: 100%;
    margin-bottom:12px;
  }
}

[data-type="radio"] .item-img-container, .default .item-img-container {
  border: 1px solid #e7d7ee;
}
