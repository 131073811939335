.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 20px;
  z-index: 500;
  display: none;
  float: left;
  text-align: left;
  list-style: none;
  background-color: white;
  background-clip: padding-box;
}

.dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding:8px;

  &.active,
  &:active {
    color: white;
    text-decoration: none;
    background: $violet-dark;
  }
}

.dropdown-menu.show {
  display: block;
}