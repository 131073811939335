.menu-popup {
  @include card();
  background: white;
  position:absolute;
  z-index:100;
  top: 55px;
  display:none;
  visibility: hidden;
  padding:25px;

  &::before {
    position: absolute;
    display: block;
    content: "";
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 8px solid #fff;
    top: -5px;
    right: 15px;

    @media (max-width: $screen-phone-max) {
      right: calc(100% / 2);
    }
  }

  .menu-items {
    margin-top:-25px;
    margin-left:-25px;
    margin-right:-25px;
  }

  .menu-item {
    a {
      display:block;
      padding:20px 25px;
      border-bottom: 2px solid $white-two;
      text-decoration: none;
      color:$slate-grey;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .help-text {
    padding-top:35px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    a {
      color: $violet;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#user-menu-popup {
  right: -5px;

  @media (max-width: $screen-phone-max) {
    left:-82px;
    right:0;
    top:48px;
    min-width:310px;
  }
  @media (min-width: $screen-tablet) {
    min-width:325px;
  }

  .btn-pma {
    width:100%;
  }

  .logout {
    padding-top:25px;
    text-align: center;
    .btn-details {
      max-width:190px;
      width:100%;
    }
  }
}

li.active > button + .menu-popup {
  display:block !important;
  visibility: visible !important;
}