.sepa-step {
  margin-left:-20px;
  margin-right:-20px;
  padding:20px;
  display:grid;
  grid-template-columns: 60px 1fr;

  & + .sepa-step {
    border-top:2px solid #eee;
  }
}

.sepa-download {
  margin-left:-20px;
  margin-right:-20px;
  padding-left:80px;
  padding-right:20px;
  border-bottom:2px solid #eee;
  padding-bottom:20px;

  h4 {
    color: $slate-grey !important;
    text-transform: uppercase;
    margin:0 0 15px 0;
  }

  a[download] {
    display: block;
    text-decoration: none;
    width: 120px;
    height: 120px;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}