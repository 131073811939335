.selection-row {
  position: relative;
  display: none;

  &.show {
    display: flex;
  }

  @media all and (max-width: $screen-tablet-max) {
    flex-direction: column;
  }
  @media all and (max-width: $screen-tablet) {
    padding-bottom: 20px;
  }
  @media all and (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media all and (min-width: $screen-notebook) {
    display: flex;
  }

  .btn-selection {
    width: 100%;
    margin: 0 0 20px 0;
    height: 40px;
    color: $slate-grey;
    border: none;
    border-radius: 3px;
    background-color: $white;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @media all and (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
      justify-content: flex-start;
      //width: 200px;
      width: calc(100% * (1/3) - 15px);
      margin: 15px 15px 0 0;
      &:nth-child(3n+3) {
        margin: 15px 0 0 0;
      }
    }
    @media all and (min-width: $screen-notebook) {
      width: 180px;
      margin: 0 10px 10px 0;
    }
    @media all and (min-width: $screen-desktop) {
      width: 200px;
      margin: 0 21px 26px 0;
    }

    &:hover {
      cursor: pointer;
    }
    &.disabled {
      color: $slate-grey;
      background: rgba(255, 255, 255, 0.5);
      .selection-icon {

      }
      &:hover {
        cursor: not-allowed;
      }
    }

    .arrow {
      @include arrow(down);
    }

    span {
      &.title {
        br {
          display: none;
        }
      }
    }
  }

  button {
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      padding-left: 10px;
    }
    .checked {
      .selection-icon {
        background: $violet;
        color: white;
        border: none;
        &.number1, &.number2, &.number3, &.number4, &.number5, &.number6, &.number7, &.number8 {
          &:after {
            content: " ";
            border: solid #fff;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px 2px 4px 2px;
            margin: 0;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
        }
      }
    }
  }

  .selection-content {
    @include card();
    @include fade-in();
    //box-shadow: 0 10px 20px 0 rgba(95, 103, 114, 0.5);
  }

  .p-selection {
    padding: 0;
    font-size: 14px;
    width: 220px;
    color: white;
    font-weight: bold;
    @media all and (min-width: $screen-notebook) and (max-width: $screen-desktop) {
      flex: 1 0 195px;
    }
    @media all and (min-width: $screen-desktop) {
      flex: 1 0 220px;
    }
  }
}

.selection-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  vertical-align: center;
  font-size: 12px;
  border: solid 1px $slate-grey;
  border-radius: 50%;
  margin: 0 5px;

  &.number1 {
    &:after {
      content: "1";
    }
  }
  &.number2 {
    &:after {
      content: "2";
    }
  }
  &.number3 {
    &:after {
      content: "3";
    }
  }
  &.number4 {
    &:after {
      content: "4";
    }
  }
  &.number5 {
    &:after {
      content: "5";
    }
  }
  &.number6 {
    &:after {
      content: "6";
    }
  }
  &.number7 {
    &:after {
      content: "7";
    }
  }
  &.number8 {
    &:after {
      content: "8";
    }
  }
}