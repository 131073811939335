footer {
  //position: relative;
  color: white;

  position: absolute;
  right: 0;
  left: 0;

  &.fixed {
    position: fixed;
    bottom: 0;
  }


  @include gradient(dark);

  &.blur {
    @media all and (max-width: $screen-tablet-max) {
      @include blur();
    }
  }

  .imprint-background {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .imprint-line, .social-line {
    display: flex;

    @media all and (max-width: $screen-tablet-max) {
      flex-flow: column;
      align-items: center;
      justify-content: space-around;
      padding:15px 0;
    }
    @media all and (min-width: $screen-notebook) {
      justify-content: space-between;
      align-items: center;
    }
  }

  @media all and (max-width: $screen-tablet-max) {
    .social-line {
      height: 105px;
    }
    .imprint-line {
      height: 65px;
    }
  }
  @media all and (min-width: $screen-notebook) {
    .social-line {
      height: 57px;
    }
    .imprint-line {
      height: 33px;
    }
  }

  .imprint-line a {
    display:inline-block;
    color:white;
    text-decoration: none;
    font-weight:600;

    &:hover {
      text-decoration: underline;
    }
    & + a {
      margin-left:8px;
    }
  }
}