.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 200; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: white;
  margin: 15% auto; /* 15% from the top and centered */
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius:10px;
  max-width: 700px;
  min-width: 300px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -7px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding:20px;
  border-bottom:1px solid #ddd;
}
.modal-body {
  padding:20px;
}