@mixin gradient($style) {
  @if $style == dark {
    background-image: linear-gradient(to left, $violet-dark, $violet-darker);
  } @else if $style == bright {
    background-image: linear-gradient(135deg, #4e378a, #8556ff 31%, #a039ad 74%, #cf16bd);
  } @else if $style == violet-red-left {
    background-image: linear-gradient(to left, $violet, $red)
  } @else if $style == violet-red-right {
    background-image: linear-gradient(to right, $violet, $red)
  }
}

@mixin container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media all and (min-width: $screen-tablet) {
    max-width: $screen-tablet;
    padding-left:40px;
    padding-right:40px;
  }
  @media all and (min-width: $screen-notebook) {
    max-width: $screen-notebook;
    padding-left:20px;
    padding-right:20px;
  }
  @media all and (min-width: $screen-desktop) {
    max-width: $screen-desktop;
  }
}

@mixin h1($margin: 0 0 0 0, $padding: 0 0 0 0, $color: $violet-dark) {
  font-size: 42px;
  color: $color;
  line-height: 50px;
  margin: $margin;
  padding: $padding;
  font-weight:bold;
}

@mixin h2($margin: 0 0 0 0, $padding: 0 0 0 0) {
  font-size: 30px;
  color: $violet-dark;
  line-height: 35px;
  margin: $margin;
  padding: $padding;
  font-weight:600;
}

@mixin h3($margin: 0 0 0 0, $padding: 0 0 0 0, $color: $violet-dark) {
  font-size: 23px;
  color: $color;
  line-height: 28px;
  margin: $margin;
  padding: $padding;
  font-weight:600;
}

@mixin h4($margin: 0 0 0 0, $padding: 0 0 0 0) {
  font-size: 26px;
  color: $violet-dark;
  line-height: 31px;
  margin: $margin;
  padding: $padding;
  font-weight:bold;
}

@mixin h5($margin: 0 0 0 0, $padding: 0 0 0 0, $color: $violet-dark) {
  font-size: 16px;
  color: $color;
  line-height: 19px;
  margin: $margin;
  padding: $padding;
  font-weight:bold;
}

@mixin h6($margin: 0 0 0 0, $padding: 0 0 0 0, $color: $slate-grey) {
  font-size: 14px;
  color: $color;
  line-height: 19px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  margin: $margin;
  padding: $padding;
}

@mixin p($margin: 0 0 0 0, $padding: 0 0 0 0) {
  font-size: 16px;
  color: $slate-grey;
  font-weight: lighter;
  margin: $margin;
  padding: $padding;
}

@mixin letter-spacing($font-size: 16px) {
  text-transform: uppercase;
  font-weight:bold;
  letter-spacing:2px;
  line-height:19px;
  font-size:$font-size;
}

@mixin visible($visible: true) {
  @if $visible == false {
    display: none;
    visibility: hidden;
  } @else {
    display: block;
    visibility: visible;
  }
}

@mixin card() {
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
  border-radius: 6px;
}

@mixin horizontal-line($margin: 0 0 0 0, $color: $white-two) {
  display: block;
  width: 100%;
  height: 1px;
  background: $color;
  margin: $margin;
  padding: 0;
}

@mixin arrow($pos) {
  border: solid $slate-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 0 5px;
  @if $pos == down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

@mixin blur($tendency: 2px) {
  filter: blur($tendency);
  transition: 0.5s filter linear;
  -webkit-transition: 0.5s -webkit-filter linear;
  -moz-transition: 0.5s -moz-filter linear;
  -ms-transition: 0.5s -ms-filter linear;
  -o-transition: 0.5s -o-filter linear;
}

@mixin tooltip($hover: true) {
  position: relative;

  .tooltip-text {
    display: none;
  }

  @if $hover == true {
    &:hover {
      .tooltip-text {
        display: block;
      }
    }
  }

  &.show {
    .tooltip-text {
      display: block;
    }
  }
}

@mixin tooltip-text($pos: top-left) {
  position: absolute;
  z-index: 1;
  width: 250px;
  min-height: 40px;
  background-color: $white;
  color: $slate-grey;
  text-align: left;
  padding: 13px;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px $white-two;
  font-size:16px;
  text-transform: none;
  line-height: 1.3;
  font-weight: normal;

  @if $pos == top-left {
    &::before {
      position: absolute;
      display: block;
      content: "";
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 5px solid #fff;
      bottom: -5px;
      left: 15px;
    }
  } @else if $pos == top-right {
    &::before {
      position: absolute;
      display: block;
      content: "";
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 5px solid #fff;
      bottom: -5px;
      right: 50px;
    }
  } @else if $pos == left-mobile {
    &::before {
      position: absolute;
      display: block;
      content: "";

      @media (max-width: $screen-phone-max) {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 5px solid #fff;
        bottom: -5px;
        left: 15px;
      }
      @media (min-width: $screen-tablet) {
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 5px solid #fff;
        top: 10px;
        left: -5px;
      }
    }
  }
}

@mixin fade-in($time: 0.5s) {
  -webkit-animation: fadein $time; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein $time; /* Firefox < 16 */
  -ms-animation: fadein $time; /* Internet Explorer */
  -o-animation: fadein $time; /* Opera < 12.1 */
  animation: fadein $time;
}

@mixin flex-box($columns: 0) {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  @if $columns == 2 {
    @media all and (max-width: $screen-notebook) { > * {  flex: 0 auto;  } }
    @media all and (min-width: $screen-notebook) { > * {  flex: 0 50%;  } }
  } @else if $columns == 4 {

  }
}
