.user-nav {
  @media (max-width: $screen-tablet-max) {
    display:none !important;
    visibility: hidden !important;
  }
  height: initial !important;

  ul, li {
    margin:0;
    padding:0;
    list-style: none;
  }

  ul {
    display:flex;
    justify-content: center;
    padding:25px 0;
  }

  li + li {
    margin-left:25px;
  }

  a {
    display:block;
    line-height:40px;
    color: #4e378a;
    opacity: 0.35;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(141, 62, 175, 0.36);
    background-color: white;
    padding:0 30px;
    text-decoration: none;

    &:hover, &.active {
      opacity: 1;
    }
  }
}