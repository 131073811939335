.selection-content {
  position: absolute;
  z-index: 250;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 6px;
  min-width: 100%;
  margin-bottom: 120px;

  @media all and (min-width: $screen-desktop) {
    top: 75px;
  }

  header {
    position:relative;
    padding: 20px 70px 18px 25px;
    background: white;
    border-radius: 20px 20px 0 0;
    border-bottom: 2px solid #e8e8e8;

    @media (min-width: $screen-tablet) {
      padding-left: 37px;
    }

    h5 {
      &.selection-select-text {
        b {
          @include h5(0, 0, $violet-dark);
        }
        @include p();
        letter-spacing: normal;
        text-transform: none;
      }
    }

    .close {
      position:absolute;
      background: transparent;
      border: none;
      top: calc(50% - 6px);
      right: 20px;

      .icon-close {
        display: block;
        height: 25px;
        width: 25px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  main {
    padding: 20px 20px 40px 20px;
  }

  .selection-arrow {
    display: none;
    position: absolute;
    z-index: 200;
    top: -8px;
    left: 270px;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background: white;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);

    @media all and (min-width: $screen-desktop) {
      display: block;
    }
  }

  &.brand .selection-arrow, &.wheel .selection-arrow {
    left: 281px;
  }
  &.model .selection-arrow, &.soundsystem .selection-arrow {
    left: 503px;
  }
  &.submodel .selection-arrow, &.heights .selection-arrow  {
    left: 723px;
  }
  &.radio .selection-arrow, &.antenna .selection-arrow {
    left: 945px;
  }

}