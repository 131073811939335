.tips-links {
  border-bottom:1px solid #eee;
  min-height:50px;
  margin-bottom:25px;

  & > div {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .tips-text {
      @include letter-spacing();
      color: $slate-grey;
      flex: 0 1 180px;
      padding-top: 17px;
    }
    .tips-letter-links {
      margin:10px 0;
      display:flex;
      a {
        text-decoration: none;
      }
      a + a {
        margin-left:10px;
      }
    }
  }
}

.tips {
  .tip-group {
    display:flex;
    flex-wrap:wrap;
    margin-top:20px;
    margin-bottom:20px;
    border-bottom:1px solid #eee;
    @media (min-width: $screen-tablet) {
      flex-wrap:nowrap;
    }
    @media (min-width: $screen-notebook) {
      margin-top: 45px;
    }

    h2 {
      @include h3(0, 0);
      margin-bottom:30px;

      @media (max-width: $screen-phone-max) {
        flex: 1 0 100%;
      }
      @media (min-width: $screen-tablet) {
        flex: 0 0 190px;
      }
      @media (min-width: $screen-notebook) {
        flex: 0 0 332px;
      }
    }

    h3 {
      @include h5();
    }

    .tip-group-list {
      .tip {
        display:flex;
        flex-wrap: nowrap;
      }
      .tip-content {
        margin-left:25px;
        margin-bottom:20px;

        & > p {
          margin:0;
          padding:0;
          line-height:23px;
        }
      }
    }

    .tip-image-items {
      display:grid;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: $screen-tablet-max) {
        grid-template-columns: 1fr 1fr;
        .top-image-item {
          padding-bottom:30px;
        }
      }
      @media (max-width: $screen-phone-max) {
        grid-template-columns: 1fr;
      }

      img {
        display: block;
        width:100%;
      }
    }

    web-accordion {
      border-bottom:1px solid #eee;
      margin-bottom:30px;

      h3 {
        cursor: pointer;
        border-top:1px solid #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 0;
      }

      .btn-quick-circle {
        position: relative;

        &::after {
          position: absolute;
          display: block;
          content: "";
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid $violet-dark;
          top: 14px;
          right: 11px;
        }
      }
    }
  }
}
