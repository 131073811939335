$box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19);
$border-radius: 4px;
$border-dark: 1px solid #555;
$font-color-dark: #eee;
$font-color-dark-deemphasized: #999;
$bg-dark: #333;
$red2: #b24e14;
$red1: #ba1e04;
$yellow1: #f8c200;
$yellow2: #d4d026;
$blue3: #005091;
$blue2: #003f8a;
$blue1: #0885ba;
$blue4: #9ac3d4;
$green1: white;
$green2: #006a4e;
$green3: #01440c;

$full-width: 1024px;

@mixin make-modal($centerWidth, $windowWidth) {
  z-index: 999999;
  box-shadow: $box-shadow;

  width: 100%;
  max-height: 98%;

  top: 50%;
  transform: translateY(-50%);

  position: fixed;
  overflow: auto;
  background: $bg-dark;
  color: $font-color-dark;

  @media(min-width: $centerWidth) {
    border-radius: $border-radius;
    position: relative;
    margin: 0 auto;
    max-width: $windowWidth;
    height: auto;
    width: auto;
  }

}

.klaro {
  .cookie-modal,
  .cookie-notice {

    @import 'switch';

    font-size: 14px;

    .slider {
      box-shadow: $box-shadow;
    }

    a {
      color: $green1;
      text-decoration: none;
    }

    p,
    strong,
    h1,
    h2,
    ul,
    li {
      font-family: inherit;
      color: $font-color-dark;
    }

    p,
    h1,
    h2,
    ul,
    li {
      display: block;
      text-align: left;
      margin: 0;
      padding: 0;
      margin-top: 0.7em;
    }

    .cm-link {
      margin-right: 0.5em;
      vertical-align: middle;
    }

    .cm-btn {
      border: none;
      text-align: center;
      display: inline-block;
      text-decoration: none;
      font-size: 13px;
      font-weight: 700;
      color: #fff;
      line-height: 40px;
      padding: 0 10px;
      border-radius: 50px;
      letter-spacing: .05em;
      transition-duration: .5s;
      text-transform: uppercase;
      margin-top:8px;
      margin-right:8px;

      &:disabled {
        opacity: 0.5;
      }

      &.cm-btn-danger {
        background-color: #e7d7ee;
        color: #8d3eaf;
      }

      &.cm-btn-success {
        background-image: linear-gradient(to right,#cf16bd,#ff3d00);
      }
    }
  }

  .cookie-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: 1000;

    .cm-bg {
      background: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
    }

    .cm-modal {

      @include make-modal(800px, 640px);

      .hide {
        border-style: none;
        background: none;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;

        svg {
          stroke: $font-color-dark;
        }

      }

      .cm-footer {
        padding: 1em;
        border-top: $border-dark;

        &-buttons {
          display: flex;
          flex-flow: row;
          justify-content: space-between;

          @media (max-width: 767px) {
            flex-flow: column;
          }
        }

        .cm-powered-by {
          display:none;
          visibility: hidden;
        }
      }

      .cm-header {
        padding: 1em;
        padding-right: 24px;
        border-bottom: $border-dark;

        h1 {
          margin: 0;
          font-size: 2em;
          display: block;

          &.title {
            padding-right: 20px;
          }
        }
      }

      .cm-body {
        padding: 1em;

        ul {
          display: block;
        }

        span {
          display: inline-block;
          width: auto;
        }

        ul.cm-apps,
        ul.cm-purposes {
          padding: 0;
          margin: 0;

          li.cm-purpose {
            .cm-apps {
              .cm-caret {
                color: #888;
              }

              .cm-content {
                margin-left: -40px;
                display: none;

                &.expanded {
                  margin-top: 10px;
                  display: block;
                }
              }
            }
          }

          li.cm-app,
          li.cm-purpose {
            position: relative;
            line-height: 20px;
            vertical-align: middle;
            padding-left: 60px;
            min-height: 40px;

            &:first-child {
              margin-top: 0;
            }

            .switch {
              position: absolute;
              left: 0;
            }

            p {
              margin-top: 0;
            }

            p.purposes {
              font-size: 0.8em;
              color: $font-color-dark-deemphasized;
            }

            &.cm-toggle-all {
              border-top: $border-dark;
              padding-top: 1em;
            }

            span.cm-list-title {
              font-weight: 600;
            }

            span.cm-opt-out,
            span.cm-required {
              padding-left: 0.2em;
              font-size: 0.8em;
              color: $font-color-dark-deemphasized;
            }
          }
        }
      }
    }
  }

  .cookie-notice:not(.cookie-modal-notice) {

    background: $bg-dark;
    z-index: 999999;

    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;

    @media(min-width: $full-width) {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      position: fixed;
      bottom: 20px;
      right: 20px;
      max-width: 500px;
    }

    @media(max-width: ($full-width - 1)) {
      border-style: none;
      border-radius: 0;
    }

    .cn-body {
      margin-bottom: 0;
      margin-right: 0;
      bottom: 0;

      padding: 1em;
      padding-top: 0;

      p {
        margin-bottom: 0.5em;
      }

      p.cn-changes {
        text-decoration: underline;
      }

      .cn-learn-more {
        display: inline-block;
      }

      .cn-ok {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        margin-top: 1em;
      }
    }
  }


  .cookie-modal-notice {

    @include make-modal(400px, 400px);

    padding: 1em;
    padding-top: 0.2em;

    .cn-ok {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;

      margin-top: 1em;
    }
  }

  .cookie-notice-hidden {
    display: none !important;
  }
}