nav {
  border-bottom:1px solid $light-lines;
  @include gradient(dark);

  &.blur {
    @media (max-width: $screen-tablet-max) {
      @include blur();
    }
  }

  &.home {
    position:relative;
    z-index:100;
    background:transparent;

    @media (max-width: $screen-phone-max) {
      height: $nav-height-phone-home;
    }
  }

  /*
  @media (max-width: $screen-phone-max) {
    &.normal #search {
      display:none !important;
      visibility: hidden !important;
    }
  }
  */

  @media (max-width: $screen-phone-max) {
    height: $nav-height-phone;
  }
  @media (min-width: $screen-tablet) {
    height: $nav-height-tablet;
  }
  @media (min-width: $screen-notebook) {
    height: $nav-height-notebook;
  }

  .nav-wrapper {
    margin:0 auto;
    padding:10px 20px 0 20px;
    max-width: $screen-desktop;
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    @media (min-width: $screen-notebook) {
      justify-content: space-between;
    }
  }

  #search {
    @media (max-width: $screen-phone-max) {
      border-top:1px solid $light-lines;
      flex:1 0 100%;
      margin:0 -20px;
      padding:10px 35px;
      order: 5;
    }
    @media (min-width:$screen-tablet) and (max-width: $screen-tablet-max) {
      margin-right:10px;
      order: 2;
    }
    @media (min-width: $screen-notebook) {
      order: 1;
    }

    form {
      position: relative;
    }

    input[name="search"] {
      width:200px;
      @media (max-width: $screen-phone-max) {
        width: 100%;
      }
      height: 39px;
      padding: 0 10px 0 50px;
      border-radius: 50px;
      border: solid 1px $light-lines;
      background: transparent;
      transition: all 500ms;
      outline: none;
      color: $white;
      text-decoration: none;

      &::placeholder {
        color: $white;
      }
      &:active, &:focus {
        background: rgba(238,238,238,0.4);
        box-shadow: 0 0 14px 0 rgba(176, 39, 123, 0.5);
      }
    }

    button {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
    }
  }

  #logo {
    order: 2;
    text-align: center;
    margin-top: 8px;
    @media (max-width: $screen-tablet-max) {
      order: 1;
      flex: 0 100%;
    }
    @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
      margin-bottom:20px;
      margin-top:10px;
    }

    img {
      max-width:240px;

      @media all and (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
        max-width:342px;
      }
      @media all and (min-width: $screen-desktop) {
        max-width:300px;
      }
    }
  }

  #menu {
    order: 3;

    @media all and (min-width: $screen-notebook) {
      flex: 0 auto !important;
    }

    ul {
      text-align: right;
      padding: 0;
      margin: 0;
    }

    ul, li {
      list-style: none;
      display: flex;
    }

    li {
      height: 39px;
      min-width: 39px;
      border-radius: 50px;
      border: solid 1px $light-lines;
      margin: 10px 5px;
      justify-content: center;
      align-items: center;

      @media (min-width: $screen-tablet) {
        position:relative;
      }

      .triangle-down {
        &::after {
          position: absolute;
          display: block;
          content: "";
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #fff;
          top: 17px;
          right: 10px;
        }
      }

      button {
        display:block;
        border: none;
        background: none;
        color: white;
        outline: none;
        cursor: pointer;
        padding:0 8px 0 0;
        margin:0;
        text-transform: uppercase;
        height: 100%;
        width: 100%;
        font-size:14px;
        font-weight:700;

        & > div {
          display:inline-block;
          vertical-align: middle;
        }
      }

      .cart-items {
        position: absolute;
        top: -10px;
        right: -10px;
        color: $white;
        height: 24px;
        width: 24px;
        text-align: center;
        line-height: 24px;
        vertical-align: middle;
        background-color: $violet;
        border-radius: 12px;
      }

      &.active {
        background: #b2a2dd;
      }

      &.login {
        position: relative;
        width: 100px;
        padding: 0 10px;
        text-align: left;
        @media all and (min-width: $screen-desktop) {
          width: 105px;
        }
      }

      &.logout {
        position: relative;
        width: 120px;
        padding: 0 10px;
        text-align: left;
        @media all and (min-width: $screen-desktop) {
          width: 125px;
        }
      }

      &.menu-item-cart {
        position:relative;
      }

      &.language {
        width: 60px;
        padding: 0 10px;
        position:relative;
      }
    }
  }
}