section {

  // Ausnahme im Cart
  @media (min-width: $screen-tablet) {
    &#cart .total-price {
      padding-right:50px;
    }
  }

  &#cart, &#checkout {
    min-height: calc(100vh - 83px - 90px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: $white-three;

    .icon-product-availability {
      margin: 5px 0 7px 0;
    }

    .cart-wrapper {
      padding:0 5px;
      width:100%;
      max-width: 350px;
      padding-bottom: 50px;
      @media (min-width: $screen-tablet) {
        max-width: 700px;
      }
      @media (min-width: $screen-notebook) {
        max-width: 780px;
      }

      header {
        padding: 58px 0 25px 0;
        background-color: transparent !important;
      }

      .header {
        min-width: 100%;
        @include flex-box();
        justify-content: center;

        h1 {
          @include h1(0, 0 0 5px 0);
          @media (max-width: $screen-phone-max) {
            font-size: 26px;
          }
        }

        h2 {
          @include h2();
        }

        .model {
          font-size: 15px;
          color: $slate-grey;
          font-weight: bold;
          line-height: 1.2;
          text-align: left;
        }
        .btn-back {
          color: $slate-grey;
          display: block;
          width: 89px;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          background-color: #dddddd;
          text-align: center;
          text-decoration: none;
          font-size: 17px;
        }
      }
    }

    h2 {
      @include h1(0, 30px 0 46px 15px);
      @media (max-width: $screen-phone-max) {
        font-size: 32px;
      }
      @media (min-width: $screen-notebook) {
        text-align: center;
      }
    }

    h3 {
      @include h3(0, 30px 30px 15px 30px, $slate-grey);
      @media (max-width: $screen-phone-max) {
        padding-left:15px;
        padding-right:15px;
      }
      font-weight: bold;
      border-bottom: 2px solid #e8e8e8;
    }

    .cart, .checkout {
      @include card();
      background: $white;

      .checkout-form, .checkout-buttons {
        padding: 15px;
        @media (min-width: $screen-tablet) {
          padding: 20px 30px;
        }
      }

      .checkout-form {
        textarea {
          width: 90%;
          @media all and (min-width: $screen-tablet) {
            width: 98%;
          }
        }
        .checkout-buttons {
          padding: 0;
        }
      }

      .product-list {
        .product-quantity {
          .form-control {
            margin: 0;
          }
        }
        .product-title {
          a {
            display:block;
            color: $violet-dark;
            text-decoration: none;
            font-weight: bold;
          }
        }

        @media (max-width: $screen-phone-max) {
          padding:0 15px;

          .product {
            display: grid;
            grid-template-columns: 80px 1fr 80px;
            grid-template-areas:
              "image title actions"
              "availability quantity total";

            & > div {
              display:flex;
              align-items:center;
            }

          }

          .product-image {
            grid-area: image;
          }
          .product-title {
            grid-area: title;
            flex-direction: column;
            align-items: start !important;
            justify-content: center;
          }
          .product-actions {
            grid-area: actions;
            justify-content: right;
          }
          .product-availability {
            grid-area: availability;
          }
          .product-quantity {
            grid-area: quantity;
          }
          .product-total {
            grid-area: total;
            justify-content: right;
          }
        }

        @media (min-width: $screen-tablet) {
          padding: 20px 30px;

          .product {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;

            .product-image {
              flex:0 0 70px;
            }
            .product-title {
              flex:1 1 300px;
            }
            .product-availability {
              flex:0 0 90px;
            }
            .product-quantity {
              flex:0 0 90px;
            }
            .product-total {
              flex:0 0 120px;
              text-align: right;
            }
            .product-actions {
              padding-left:20px;
              flex:0 0 50px;
            }
          }
        }
      }

      .cart-totals-wrapper {
        margin-top: 20px;
        border-top: 2px solid #e8e8e8;

        .cart-totals {
          display: flex;
          justify-content: space-between;
          border-top:1px solid white;
          padding: 15px;
          color: $violet-dark;
          font-weight: bold;

          @media (min-width: $screen-tablet) {
            padding: 15px 30px;
          }

          & + .cart-totals {
            border-top:2px solid #e8e8e8;
          }
          .total-title {
            max-width: 100%;
          }
          .total-price {
            min-width: 120px;
            text-align: right;
          }
        }

        & > :nth-child(2), & > :nth-child(3) {
          .total-title, .total-price {
            color: $slate-grey;
            font-weight: normal;
          }
        }
        & > :nth-child(4) {
          background:$pale-grey;
        }
        & > :nth-child(7) {
          background:$pale-grey;
          .total-title, .total-price {
            font-size: 24px;
          }
        }
        & > :nth-child(8) {
          background:$pale-grey;
        }
      }

      .cart-below-minimum {
        margin: 20px 20px 10px 20px;
        font-weight: bold;
        padding: 10px 15px;
        border: 2px solid $red;
        border-radius: 10px;
        color: $red;
      }

      .checkout-options {
        margin-bottom: 20px;
      }

      .checkout-buttons {
        display: flex;
        justify-content: center;
        @media (max-width: $screen-phone-max) {
          flex-direction: column;
          button {
            margin-bottom:15px;
          }
        }
        @media (min-width: $screen-tablet) {
          justify-content: flex-end;
          button {
            margin-right:25px;
          }
        }
      }

    }
  }
}