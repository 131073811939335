.selection-results {
  min-height: 80vh;
  padding-bottom: 40px;

  &.container {
    @media all and (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  h4 {
    @include h4(0, 40px 0 0 0);
  }
  p {
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    color: $slate-grey;
    margin: 0;
  }
}

.product-selection {
  display: flex;
  background: white;
  @include card();
  margin-top: 20px;

  @media all and (max-width: $screen-phone-max) {
    min-width: 290px;
    flex-direction: column;
  }
  @media all and (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
    min-height: 380px;
  }
  @media all and (min-width: $screen-notebook) {
    min-height: 360px;
  }

  .product-selection-features {
    order: 2;
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: $screen-phone-max) {
      border-top: 1px solid $white-two;
      padding:8px 24px 0 24px;
    }

    @media (min-width: $screen-tablet) {
      order: 1;
      flex: 0 0 67px;
      padding: 16px 8px;
      border-right: 1px solid $white-two;
      flex-direction: column;
      justify-content: flex-start;
      flex-wrap: nowrap;
    }

    .product-selection-feature {
      padding-right: 14px;
      display: flex;
      align-items: center;

      @media (max-width: $screen-phone-max) {
        width:54px;
        padding-bottom:8px;
        img {
          display:block;
          margin:0 auto;
        }
      }
      @media (min-width: $screen-tablet) {
        padding-right: unset;
        margin-top: 8px;
        &:first-child {
          margin-top: unset;
        }
      }
    }
  }
}

.product-selection-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  order: 1;
  @media all and (max-width: $screen-phone-max) {
    img {
      width: 100%;
      max-height: 220px;
      object-fit: contain;
      margin: 8px 0;
    }
  }
  @media all and (min-width: $screen-tablet) {
    order: 2;
    flex: 0 0 320px;
    padding: 20px 12px;
    img {
      max-width: 300px;
      max-height: 290px;
      object-fit: cover;
    }
  }
  @media all and (min-width: $screen-notebook) {
    flex: 0 0 400px;
    img {
      //width: 300px;
      //height: 270px;
      //object-fit: cover;
      //margin-bottom: 10px;
    }
  }

  .product-selection-current-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .btn-prev-img {
      display: none;
    }
    .btn-next-img {
      display: none;
    }
    .current-image {
      width: 100%;
      text-align: center;
    }
    @media all and (max-width: $screen-phone-max) {
      .current-image {
        height: 260px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn-prev-img {
        display: inline-block;
        width: 30px;
        height: 59px;
        border-radius: 0 30px 30px 0;
        background-color: $pale-grey-two;
        &:after {
          content: '';
          border: solid $violet-darker;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 6px;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          margin: 22px 0 0 8px;
        }
      }
      .btn-next-img {
        display: inline-block;
        width: 30px;
        height: 59px;
        border-radius: 30px 0 0 30px;
        background-color: $pale-grey-two;
        &:after {
          content: '';
          border: solid $violet-darker;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 6px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin: 22px 8px 0 5px;
        }
      }
    }

  }

  .product-selection-additional-images {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    margin: 20px 0 5px 0;
    @media all and (max-width: $screen-phone-max) {
      @include visible(false);
    }
    @media all and (min-width: $screen-desktop) {
      width: 70%;
    }
    .additional-image {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px $white-two;
      padding: 3px;
      margin: 0 3px;

      width: calc(100% * (1/4) - 2px);

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.product-selection-content {
  position: relative;
  order: 3;
  @media all and (max-width: $screen-phone-max) {
    border-top: 1px solid $white-two;
  }
  @media all and (min-width: $screen-tablet) {
    flex: 0 1 100%;
    padding-right: 15px;
  }

  h3 {
    @media all and (max-width: $screen-phone-max) {
      font-size: 21px;
      line-height: 25px;
      margin: 8px 0 0 0;
      padding: 0 24px;
    }
    @media all and (min-width: $screen-tablet) {
      font-size: 23px;
      line-height: 28px;
      margin: 35px 0 0 0;
      padding: 0;
    }
    color: $slate-grey;
    font-weight: bold;
  }

  .product-model {
    @media (max-width: $screen-phone-max) {
      padding: 0 24px 8px 24px;
      border-bottom: 1px solid $white-two;
    }
    color: $slate-grey;
  }
  .product-description {
    @media (max-width: $screen-phone-max) {
      padding: 8px 24px;
      border-bottom: 1px solid $white-two;
    }
    @media (min-width: $screen-tablet) {
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }
  @media (min-width: $screen-tablet) {
    .product-model + .product-attributes {
      margin-top: 25px;
    }
  }
  .product-attributes {
    display: flex;

    @media (max-width: $screen-phone-max) {
      padding: 8px 24px 8px 24px;
    }
    @media (max-width: $screen-tablet-max) {
      &.top {
        flex-wrap: wrap;
        & > :nth-child(3) {
          margin-top:16px;
          flex: 1 0 100%;
        }
      }
    }
    @media (max-width: $screen-phone-max) {
      &.top > :nth-child(3) {
        border-top: 1px solid $white-two;
        margin:8px -24px 0 -24px;
        padding:8px 24px;
      }
    }

    .product-attribute {
      white-space: nowrap;

      @media (max-width: $screen-phone-max) {
        width: 120px;
      }
      @media (min-width: $screen-tablet) {
        width:130px;
      }

      h6 {
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 4px 0;
      }
      .text {
        font-size: 18px;
        @media (min-width: $screen-tablet) {
          font-size: 24px;
          line-height: 29px;
        }
        display:inline-block;
        vertical-align: middle;
        color: $slate-grey;
      }
    }
  }
}

@media (min-width: $screen-tablet) {
  .product-attributes + .product-attributes {
    padding-top: 16px
  }
}

.product-selection-actions {
  @media (max-width: $screen-phone-max) {
    padding: 14px 24px;
    .btn-pma {
      margin-right: 8px;
      margin-bottom: 16px;
    }
  }
  @media (min-width: $screen-tablet) {
    padding: 16px 0 24px 0;
    .btn-pma {
      margin-right: 8px;
    }
  }
  @media (min-width: $screen-notebook) {
    .btn-pma {
      margin-right: 20px;
    }
  }
}

