.tooltip-left-mobile {
  @include tooltip();
  .tooltip-text {
    @include tooltip-text(left-mobile);
    @media (min-width: $screen-tablet) {
      top: 0px;
      left: 45px;
    }
    @media (max-width: $screen-phone-max) {
      bottom: 43px;
      left: -7px;
    }
  }
}
.tooltip-top-left {
  @include tooltip();
  .tooltip-text {
    @include tooltip-text(top-left);
    bottom: 43px;
    left: -7px;
  }
}
.tooltip-top-right {
  @include tooltip();
  .tooltip-text {
    @include tooltip-text(top-right);
    bottom: 15px;
    right: -10px
  }
}

.tooltip-nh-top-left {
  @include tooltip(false);
  .tooltip-text {
    @include tooltip-text(top-left);
    bottom: 25px;
    left: -7px;
  }
}