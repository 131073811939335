#home-header {
  position: relative;
  @include gradient(bright);

  margin-top: -$nav-height-phone-home;
  padding-top: 146px;
  @media all and (min-width: $screen-tablet) {
    margin-top: -$nav-height-tablet;
    padding-top: $nav-height-tablet;
  }
  @media all and (min-width: $screen-notebook) {
    margin-top: -$nav-height-notebook;
    padding-top: $nav-height-notebook;
  }

  .swiper-container {
    min-height: 70vh;
    @media all and (min-width: $screen-notebook) {
      min-height: 85vh;
    }
    .swiper-slide {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 70vh;

      .start {
        //max-height: 25vh;
        padding: 2vh 0;
      }

      .end {
        max-height: 40vh;
        padding-bottom: 3vh;
        img {
          max-height: 30vh;
        }
      }

      h1 {
        color: $white;
        padding: 20px;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
      }

      @media all and (min-width: $screen-tablet) {
        min-height: 50vh;
        h1 {
          font-size: 60px;
          line-height: 70px;
          text-align: center;
          font-weight: 600;
        }
        .start {
          max-width: 70vw;
        }
        .end {
          min-height: 400px;
          img {
            max-width: 340px;
          }
        }
      }

      @media all and (min-width: $screen-notebook) {
        min-height: 80vh;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        h1 {
          font-size: 50px;
          line-height: 60px;
          text-align: center;
          font-weight: 600;
        }
        .start {
          max-height: initial;
          max-width: 500px;
          padding-right: 134px;
        }
        .end {
          min-height: initial;
          img {
            max-width: initial;
          }
        }
      }

      @media all and (min-width: $screen-desktop) {
        min-height: 80vh;

        h1 {
          font-size: 60px;
          line-height: 70px;
          text-align: left;
          font-weight: bold;
        }
        .start {
          max-height: initial;
          max-width: 550px;
          padding-right: 134px;
        }
        .end {
          min-height: initial;
          img {
            max-width: initial;
            max-height: 50vh;
          }
        }
      }
    }
  }

  .background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -100;
    opacity: 0.1;
    overflow: hidden;

    .text {
      text-transform: uppercase;
      line-height: 100vh;
      font-size: 48vw;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      text-align: center;
      color: $white;
      text-shadow: 0 0 40px #000000;
      &.alt {
        letter-spacing: 10vw;
      }
      &.neu {
        letter-spacing: 1vw;
      }
    }
  }

  .btn-pma {
    z-index:8000;
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    bottom: 70px;

    @media all and (max-width: $screen-phone-max) {
      min-width:270px;
      padding:0;
    }
  }

  .swiper-pagination-bullets {
    bottom: 40px;
  }

  .swiper-pagination-bullet {
    width:14px;
    height:14px;
    margin:0 10px;
    bottom: 40px;
  }

  .swiper-pagination-bullet-active {
    background:white;
  }
  @media all and (min-width: $screen-tablet) {
    .btn-pma {
      bottom: 90px;
    }
    .swiper-pagination-bullets {
      bottom: 50px;
    }
  }
  @media all and (min-width: $screen-notebook) {
    .btn-pma {
      bottom: 120px;
    }
    .swiper-pagination-bullets {
      bottom: 90px;
    }
  }
}

#complete-solution {
  padding-left: 15px;
  padding-right: 15px;
  background: $white-three;

  @media all and (min-width: $screen-tablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .complete-solution-card {
    position: relative;
    z-index: 1000;
    max-width:1090px;
    margin:0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: center;
    background-color: $white;
    text-align: center;
    @include card();

    @media all and (max-width: $screen-phone-max) {
      flex-direction: column;
      padding:32px 15px;
    }
    @media all and (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
      flex-direction: column;
      justify-content: center;
      align-content:center;
      padding:32px 90px;
    }
    @media all and (max-width: $screen-tablet-max) {
      margin-top:-35px;
    }
    @media all and (min-width: $screen-notebook) {
      margin-top:-80px;
      padding:40px 45px;

      & > div {
        flex: 0 1 45%;
      }
    }
  }

  img {
    width:100%;
    display: inline-block;
  }

  h2 {
    @include h1();
    @media all and (max-width: $screen-phone-max) {
      font-size:21px;
      line-height:25px;
    }
  }
  p {

  }
}

#information {
  background: $white-three;

  h2 {
    @include h1(0, 50px 0 0 0);
    text-align:center;
  }
  .subheader {
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-bottom:35px;
  }

  .information-cards {
    display: flex;
    flex-wrap: wrap;
    max-width: 1210px;
    margin: 0 auto;
    justify-content: center;

    padding-bottom:40px;
    @media all and (min-width: $screen-tablet) {
      padding-bottom:75px;
    }
    @media all and (min-width: $screen-desktop) {
      justify-content: flex-start;
    }


    .tip-card, .information-card {
      position:relative;
      @include card();
      background: $white;
      margin: 20px 10px;

      display: flex;
      flex-direction: column;

      @media all and (max-width: $screen-phone-max) {
        padding-bottom: 40px;
      }

      @media all and (min-width: $screen-tablet) {
        //min-height: 600px;
        //max-height: 600px;
      }

      @media all and (max-width: $screen-tablet-max) {
        text-align:center;
        flex:0 0 350px;
      }

      @media all and (min-width: $screen-notebook) {
        flex:0 0 282px;
      }

      img {
        height: 294px;
        @media all and (min-width: $screen-notebook) {
          height: 230px;
        }

        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        width: 100%;
        object-fit: cover;
        padding: 0;
        margin: 0;
      }

      h3 {
        @include h3(0, 30px 16px 20px 16px);
      }

      .content {
        font-size: 15px;
        line-height:22px;
        margin: 0;
        padding:0 16px;
        flex-grow: 1;
      }

      .btn-pma {
        align-self: center;
        margin: 20px 10px 0 10px;
        @media all and (min-width: $screen-tablet) {
          align-self: flex-start;
          margin: 20px 10px;
        }
      }
    }

    .tip-card {
      order: 4;

      h5 {
        @include h5(0, 22px 16px 22px 16px, $slate-grey);
        font-size: 17px;
      }
    }
  }
}

#home-footer {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: no-repeat center center;
  background-size: cover;

  height: 286px;
  @media all and (min-width: $screen-tablet) {
    height: 387px;
  }

  h1 {
    font-size: 21px;
    @media all and (min-width: $screen-tablet) {
      font-size: 30px;
    }

    text-align:center;
    margin:0;
    font-weight: bold;
    color: $white;
    padding: 25px 10px;
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.55);
  }
}
