/* buttons */
.btn-pma {
  border: none;
  text-align: center;
  display:inline-block;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
  color: $white;
  line-height: 40px;
  min-width:110px;
  padding:0 35px;
  border-radius: 50px;
  letter-spacing: 0.05em;
  @include gradient(violet-red-right);
  transition-duration: 0.5s;
  text-transform: uppercase;

  &:hover {
    @include gradient(violet-red-left);
    box-shadow: 0 0 14px 0 rgba(176, 39, 123, 0.5);
    cursor: pointer;
  }
}

.btn-circle {
  border-radius: 50px;
  display:flex;
  justify-content: center;
  align-items: center;

  &.btn-small {
    font-size:14px;
    height: 25px;
    width: 25px;

    @media all and (max-width: $screen-tablet-max) {
      height: 40px;
      width: 40px;
    }
  }

  &.btn-medium {
    font-size:15px;
    font-weight:bold;
    height: 30px;
    width: 30px;
    flex:30px 0 0;
  }

  &.btn-large {
    font-size:30px;
    font-weight:bold;
    height: 60px;
    width: 60px;
  }

  &.btn-bright {
    background-color: $pale-grey-two;
    color: $violet-dark;
  }

  &.btn-dark {
    color: $white;
    @include gradient(dark);
  }

}

.btn-details {
  text-decoration:none;
  display:inline-block;
  font-size: 16px;
  text-align: center;
  color: $red;
  line-height: 36px;
  padding-left:20px;
  padding-right:20px;
  vertical-align: middle;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(95, 103, 114, 0.22);
  background-color: $white;
  border: solid 2px $red;
  transition-duration: 0.5s;

  &:hover {
    box-shadow: 0 0 14px 0 rgba(176, 39, 123, 0.5)
  }
}

.item-infos {
  cursor:pointer;
  display:flex;
  width: 60px;
  background: white;
  color: $violet-dark;
  font-size: 12px;
  font-weight: normal;
  border-radius: 12px;
  box-shadow: 0 3px 6px 0 rgba(141, 62, 175, 0.36);
  align-items: center;

  .info-icon {
    background: $violet-bright;
    border-radius: 20px;
    padding: 2px;
    margin: 2px;
    width: 16px;
    height: 16px;
    text-align: center;
  }

  .info-text {
    padding: 0 10px 0 5px;
  }
}

.btn-quick-circle {
  display:block;
  width:30px;
  height:30px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border:none;
  background:white;
  padding:0;
  cursor: pointer;

  &.number {
    font-weight: bold;
    color: #8d3eaf;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.btn-back {
  color: $slate-grey;
  display: block;
  width: 89px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  background-color: #dddddd;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}