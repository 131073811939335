.table {
  width: 100%;
  margin-bottom:20px;
  border-collapse: collapse;
  border-spacing: 0;

  & > thead > tr > th {
    vertical-align: bottom;
    border-top:0;
    padding: 8px;
    line-height: 1.42;
    text-align: left;
    border-bottom: 2px solid #ddd;
  }

  & > tbody > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }

  .nowrap-cell {
    white-space: nowrap;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.account-page table {
  @include card();
  background:white;
  width:100%;
  margin:0 auto;
  border-spacing: 0px;

  thead {
    td {
      padding:22px 20px 14px 20px;
      color: $slate-grey;
      text-transform: uppercase;
      font-weight: bold;
      font-size:14px;
    }
  }
  tbody {
    td {
      padding: 26px 20px 21px 20px;
      border-top:2px solid $white-three;
    }
    a {
      text-decoration: none;
      color:$violet-dark;

      &:hover {
        text-decoration: underline;
      }
    }
    tr.em-line td {
      background: $violet-bright;
      font-weight: bold;
      color: $violet-dark;
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 6px;
      }
      td:last-child {
        border-bottom-right-radius: 6px;
      }
    }
  }
  strong {
    color:$violet-dark;
  }

}