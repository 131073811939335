.product-page {

  background-color: $white-three;
  header {
    background-color: transparent !important;
  }
  .header {
    @include flex-box();
    h1 {
      @include h1(0, 0 0 5px 0);
      @media (max-width: $screen-phone-max) {
        font-size:35px;
      }
    }
    h2 {
      @include h2();
    }
    .model {
      font-size: 15px;
      color: $slate-grey;
      font-weight: bold;
      line-height: 1.2;
      text-align: left;
    }
  }

  .nondescript-heading {
    @include h5(0, 20px 0 2px 0, $slate-grey);
    text-transform: uppercase;
    width: 100%;
  }
  .nondescript-text {
    padding: 0 0 10px 0;
  }
  .horizontal-line {
    @include horizontal-line(15px 0 15px 0, $white-two);
  }

  @media (max-width: $screen-phone-max) {
    .btn-prev-img {
      display: inline-block;
      width: 30px;
      height: 59px;
      border-radius: 0 30px 30px 0;
      background-color: $pale-grey-two;
      &:after {
        content: '';
        border: solid $violet-darker;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        margin: 22px 0 0 8px;
      }
    }
    .btn-next-img {
      display: inline-block;
      width: 30px;
      height: 59px;
      border-radius: 30px 0 0 30px;
      background-color: $pale-grey-two;
      &:after {
        content: '';
        border: solid $violet-darker;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        margin: 22px 6px 0 2px;
      }
    }
  }

  .product-description-attributes {
    display: flex;
    flex-wrap:wrap;
    padding: 0 18px 0 20px;
    @media (min-width: $screen-phone-max) {
      padding: 0 45px 0 45px;
    }

    .product-actions {
      flex: 1 0 100%;
      padding: 24px 0 35px 0;
    }
    .text-em {
      font-size:24px;
      font-weight:bold;
      line-height: 29px;
    }
    .text-nr {
      display:inline-block;
      font-size:24px;
      line-height: 29px;
      vertical-align: top;
    }
    .product-description-attribute {
      white-space: nowrap;

      @media (max-width: $screen-phone-max) {
        width: 145px;
      }
      @media (min-width: $screen-notebook) and (max-width: $screen-notebook-max) {
        width:170px;
      }
      @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max), (min-width: $screen-desktop) {
        width:150px;
        &.small {
          width:90px;
        }
      }

    }
  }

  main {
    &.container {
      @include container();
    }
    section {
      &.product {
        display: flex;
        flex-flow: row wrap;
        @media all and (max-width: $screen-phone-max) {
          @include card();
          background-color: $white;
        }

        section {
          &.product-current-image {
            order: 1;
            flex: 1 1 auto;
            @media (min-width: $screen-phone-max) {
              @include card();
              background-color: $white;
            }
            @media (min-width: $screen-tablet) {
              width: calc(100% * (1/2) - 10px - 1px);
              margin: 0 0 10px 0;
            }
            @media (min-width: $screen-notebook) {
              margin: 0 10px 10px 0;
            }

            display: flex;
            justify-content: space-between;
            align-items: center;

            .current-image {
              padding:10px;
            }

            img {
              display:inline-block;
              max-width:100%;
              border-radius: 6px;
            }

            @media (max-width: $screen-phone-max) {
              .current-image {
                padding: 20px 0;

                img {
                  max-height:220px;
                }
              }
            }
          }

          &.product-additional {
            display: none;
            @media all and (min-width: $screen-tablet) {
              order: 2;
             // width: 140px;
              width: auto;
              display: flex;
              flex-flow: row wrap;
            }
            @media (min-width: $screen-notebook) {
              order: 3;
              width: calc(100% * (1/2) - 10px - 1px);
              flex: 1 0 auto;
              margin: 10px 10px 10px 0;
            }
            @media (min-width: $screen-desktop) {
              flex-flow: column wrap;
            }

            section {
              &.product-additional-images {
                display: grid;

                @media (min-width: $screen-tablet) and (max-width: $screen-notebook) {
                  padding-left:10px;
                  grid-template-columns: 135px;
                  grid-template-rows: auto;
                  grid-row-gap:10px;
                  margin-bottom:10px;

                  .additional-image-box {
                    padding: 7.5px;
                  }
                }

                @media (min-width: $screen-notebook) and (max-width: $screen-desktop) {
                  grid-template-columns: 150px 150px 150px;
                  grid-template-rows: auto;
                  grid-column-gap:8px;
                  grid-row-gap:8px;

                  .additional-image-box {
                    padding: 15px;
                  }
                }

                @media (min-width: $screen-desktop) {
                  grid-template-columns: 135px 135px 135px 135px;
                  grid-template-rows: auto;
                  grid-column-gap:10px;
                  grid-row-gap:10px;

                  .additional-image-box {
                    padding: 7.5px;
                  }
                }

                .additional-image-box {
                  img {
                    max-width: 120px;
                    max-height: 120px;
                  }
                  @include card();
                  background-color: $white;
                }
              }
              &.product-note {
                display: none;
                @media (min-width: $screen-notebook) {
                  display: block;
                }
                margin: 10px 10px 10px 0;
                .note {
                  padding: 20px 0 10px 0;
                  @media all and (min-width: $screen-notebook) {
                    padding: 20px 20px 10px 0;
                  }
                  @media all and (min-width: $screen-desktop) {
                    padding: 32px 40px 10px 0;
                  }
                  h5 {
                    @include h5(0, 0, $slate-grey);
                    text-transform: uppercase;
                  }
                }
              }
            }
          }

          &.product-description {
            order: 2;
            display: flex;
            flex-flow: column wrap;
            width: 100%;

            @media (min-width: $screen-phone-max) {
              @include card();
              background-color: $white;
            }
            @media (min-width: $screen-tablet) {
              flex: 1 0 auto;
              margin: 0 0 10px 0;
            }
            @media (min-width: $screen-notebook) {
              width: calc(100% * (1/2) - 10px - 1px);
              margin: 0 0 10px 10px;
            }

            .description {
              padding: 35px 18px 0 20px;
              @media all and (min-width: $screen-phone-max) {
                padding: 35px 45px 0 45px;
              }
              h2 {
                font-size: 26px;
                color: $slate-grey;
                margin: 0;
                padding-bottom: 10px;
              }
              .product-model {
                display:flex;
                margin-bottom:20px;

                .item-infos {
                  margin-left:12px;
                }
              }
            }
          }
          &.product-information {
            order: 3;
            width: 100%;

            @media (min-width: $screen-notebook) {
              order: 4;
              width: calc(100% * (1/2) - 10px - 1px);
              flex: 1 0 auto;
              margin: 10px 0 80px 10px;
            }

            section {
              &.product-information-box {
                @media (max-width: $screen-phone-max) {
                  padding:0 0 35px 0;
                }
                @media (min-width: $screen-tablet) {
                  padding:35px 0;
                }

                section {
                  padding: 0 18px 0 20px;
                  @media (min-width: $screen-tablet) {
                    padding: 0px 35px 0 40px;
                  }
                  .nondescript-heading {
                    padding-top:0px;
                  }
                }

                .downloads {
                  .product-download {
                    border-bottom:1px solid $white-two;
                    display:flex;
                    align-items:center;
                    padding:4px 0;
                  }
                  a {
                    text-decoration:none;
                  }
                  a[target="_blank"] {
                    padding: 7px 0 3px 10px;
                  }
                  a[download] {
                    padding: 5px 0px 5px 10px;
                  }
                  .product-download-text {
                    flex: 1 1 100%;
                    display:flex;
                  }
                  .icon-document {
                    margin-right:10px;
                  }
                  .icon-download, .icon-view, .icon-document {
                    display:inline-block;
                  }
                }

                .specification {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  flex-wrap: wrap;

                  .product-specification {
                    margin-right: 20px;
                    margin-top: 12px;

                    &.tooltip {
                      @include tooltip();
                      .tooltip-text {
                        @include tooltip-text(top);
                        left: 50%;
                        right: unset;
                        top: unset;
                        transform: translateX(-50%);
                        bottom: 40px;
                        &::after {
                          border-width: 5px;
                          border-style: solid;
                          border-color: white transparent transparent transparent;
                          margin: 0;
                        }
                      }
                    }
                  }
                  img {
                    display: inline-block;
                    width: 35px;
                  }
                }

                p {
                  @include p(0, 0 0 10px 0);
                }

                @media all and (min-width: $screen-phone-max) {
                  @include card();
                  background-color: $white;
                }

              }
            }

          }
        }
      }
    }
  }



  .product-radios {
    @media (max-width: $screen-tablet-max) {
      padding-top: 25px;
    }
    @media (min-width: $screen-tablet) {
      padding-bottom: 50px;
    }

    .product-radio-model-heading {
      position:relative;
      background: $pale-grey;
      color: $violet-dark;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      margin:0;
      padding:10px 35px 10px 20px;
      border-radius:20px;
      cursor:pointer;

      .note {
        font-size:12px;
        line-height: 14px;
        margin-left:8px;
        font-weight:normal;
      }
    }

    [open] .product-radio-model-heading {
      border-bottom-left-radius:0px;
      border-bottom-right-radius:0px;
    }

    .product-radio-items {
      padding:15px;
      background: $pale-grey;
      position:relative;
      grid-column-gap:15px;
      grid-row-gap:15px;
      border-bottom-left-radius:20px;
      border-bottom-right-radius:20px;

      @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
        display:grid;
        grid-template-columns: 1fr 1fr;
      }
      @media (min-width: $screen-notebook) and (max-width: $screen-notebook-max) {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (min-width: $screen-desktop) {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    .btn-prev-img {
      position:absolute;
      left:15px;
      top: calc(50% - 29px);
    }
    .btn-next-img {
      position:absolute;
      right:15px;
      top: calc(50% - 29px);
    }
    .product-radio-item {
      @media (max-width: $screen-phone-max) {
        display:none;
      }

      @include card();
      border-radius:20px;

      .item-img-container {
        background: white;
        border-radius: 0 0 20px 20px ;
        img {
          width:100%;
          display:block;
          border-radius: 0 0 20px 20px ;
        }
      }

      .item-title {
        color: $violet-dark;
        background-color: white;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        line-height: 1.21;
        padding:14px 18px;
        border-radius: 20px 20px 0 0;
        border-bottom: 1px solid $white-three;
      }
    }
  }

  [data-selected-radio="1"] {
    display:block !important;
  }

  .product-note-mobile {
    @media all and (min-width: $screen-notebook) {
      display: none;
    }

    .note {
      margin:0 auto;
      max-width: $screen-tablet - 40px;
      padding: 20px 25px 50px 25px;
      h5 {
        @include h5(0, 0, $slate-grey);
        text-transform: uppercase;
      }
    }
  }
}