#cookie-warning {
  z-index: 999999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #eee;
  padding: 14px 20px;
  border-top: #ccc 1px solid;
  font-size: 20px;
  vertical-align: baseline;
  align-items: center;
  justify-content: space-between;
  display:none;

  &.show {
    display: flex !important;
  }
}