.information-images {
  display:flex;
  flex-wrap: nowrap;
  justify-content: center;
  
  @media (min-width: $screen-notebook) {
    .information-image-mobile {
      visibility: hidden;
      display: none !important;
    }
  }
  @media (max-width: $screen-tablet-max) {
    .information-image {
      visibility: hidden;
      display: none !important;
    }
  }
  @media (min-width: $screen-desktop) {
    background: $pale-grey;
    height: 210px;
    margin-bottom: 250px;

    .information-image {
      flex: 0 1 1200px;
      height:420px;
      border-radius:10px;
    }
  }
  @media (max-width: $screen-notebook-max) {
    .information-image-mobile, .information-image {
      width:100%;
    }
  }

}

.information {
  margin-top:62px;

  h2 {
    @include h2();
  }
  h6 {
    @include h6(0 0 10px 0, 0);
  }

  .information-content {
    margin-bottom:130px;

    &-image {
      img + img {
        margin-top:25px;
      }
    }

    @media (max-width: $screen-tablet-max) {
      flex-direction: column;
      justify-content: center;

      &-text {
        margin-bottom:25px;
      }
    }
    @media (min-width: $screen-notebook) {
      &-text {
        flex: 1 0 60%;
        padding-right:20px;
      }
      &-image {
        flex: 0 1 40%;
      }
    }
    &-image img {
      display:block;
      width:100%;
    }
  }
}

.information-faq {
  .heading {
    display: flex;
    margin-bottom:40px;

    @media all and (max-width: $screen-phone-max) {
      flex-direction: column;
      h2 { margin-bottom: 20px; }
    }
    @media all and (min-width: $screen-tablet) {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  .faq {
    border-top:1px solid $white-four;
    padding-top:25px;
    padding-bottom:30px;
    display:flex;

    @media all and (max-width: $screen-tablet-max) {
      flex-direction: column;
    }
  }

  .faq-question {
    color:$violet-dark;
    font-weight:600;
    padding-bottom:12px;
    @media all and (min-width: $screen-notebook) {
      flex: 0 1 378px;
      padding-right: 62px;
    }
  }

}
