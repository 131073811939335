.register-page {
  h3, h4, strong {
    color: $violet-dark;
  }
  .radio-container {
    font-size:10px;
    margin-top: 15px;
    display: flex;
    align-items: center;

    input[type="checkbox"] {
      margin-right:7px;
    }
  }

  @media (max-width: $screen-phone-max) {
    min-height: calc(100vh - 352px);
  }
  @media (min-width: $screen-notebook) {
    min-height: calc(100vh - 172px);
  }
}