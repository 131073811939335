header {
  &.selection-header {
    @media (max-width: $screen-phone-max) {
      .container {
        padding: 0 20px;
      }
    }

    @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
      .container {
        padding: 0 60px;
      }
    }

    @media (min-width: $screen-notebook) {
      padding: 0 0 40px 0;
    }

    @media (max-width: $screen-tablet-max) {
      #selection-header {
        &.blur {
          @include blur();
        }
      }
      &.selection-header {
        padding: 0;
      }
    }

    div {
      padding: 0;
    }
    .container {
      @include container();
    }
    &.selection-header {
      @include gradient(dark);

      margin:0;

      h5 {
        @include h5(0, 28px 0 20px 0, $white);
        @include letter-spacing();
        &.baseline {
          @media (max-width: $screen-phone-max) {
            display: none;
          }
        }

        @media (min-width: $screen-notebook) {
          padding-bottom: 27px;
        }
      }

      @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
        .container {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
        }
        #selection-button-list { flex: 0 100%; }
      }

      #show-hide-menu {
        font-size: 16px;
        color: white;
        font-weight: bold;
        padding: 20px 0;
        cursor: pointer;

        @media (min-width: $screen-tablet) {
          margin: 0 10px 0 0;
        }

        .show-hide-menu-text {
          margin-top:9px;
          padding-right:10px;
        }

        .arrow {
          border: solid white;
          border-width: 0 2px 2px 0;
          padding: 5px;
          margin: 0 7px;
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transition: all 500ms;
        }

        &.open {
          .arrow {
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg)
          }
        }
      }
    }
  }
}