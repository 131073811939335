.selection-items {
  grid-template-rows: auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;

  @media (min-width: $screen-tablet) {
    display:grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: $screen-phone-max) {
    display:flex;
    flex-direction: column;
    button + button {
      margin-top:20px;
    }
  }
  button {
    background:none;
    border:none;
    padding:0;
  }

  .item-infos {
    position:absolute;
    right:18px;
    top: calc(50% - 10px);
  }
}

.brand .selection-items {
  display:grid;

  @media (max-width: $screen-tablet-max) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: $screen-phone-max) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: $screen-notebook) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  button {
    margin:0;
  }
}