@font-face {
  font-family: 'ProximaNova';
  src: url(../../fonts/ProximaNova-Regular.otf);
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url(../../fonts/ProximaNova-Bold.ttf);
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'ProximaNova';
  src: url(../../fonts/ProximaNova-Semibold.otf);
  font-style: normal;
  font-weight: 600;
}

html {
  height: 100%;
  box-sizing: border-box;
}

strong {
  font-weight: bold;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
  font-family: ProximaNova, sans-serif;
  font-style: normal;
  color: $slate-grey;
  font-size: 16px;
  line-height: 1.4;
}

input, button {
  &:focus {
    outline: none !important;
  }
}

.container {
  @include container();
}

br {
  line-height: normal;
}

.background-page {
  background-color: $white-three;

  .form-container {
    padding-top:30px;
    padding-bottom:30px;
  }

  .page {
    padding-top:30px;
    padding-bottom:30px;
  }

  @media all and (min-width: $screen-notebook) {
    height: 100%;
    min-height: calc(100vh - 172px);
  }
}

.simple-background-page {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: $white-three;
  padding:20px 20px;
  height: 100%;

  @media (max-width: $screen-phone-max) {
    min-height: calc(100vh - 301px);
  }
  @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
    min-height: calc(100vh - 356px);
  }
  @media (min-width: $screen-notebook) {
    min-height: calc(100vh - 172px);
  }
}

.page-size {
  min-height: 80vh;
}

.horizontal-line {
  @include horizontal-line(0, $light-lines);
}

/* Hide or Show Content */
.hide-mobile {
  @media (max-width: $screen-phone-max) {
    display: none !important;
    visibility: hidden !important;
  }
}

.hide-tablet-and-above {
  @media (min-width: $screen-tablet) {
    display: none !important;
  }
}

.show-tablet-only {
  display: none !important;
  @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
    display: block !important;
  }
}

.action-note {
  display: block;
  font-size: 11px;
  padding-top: 7px;
  color: $white-four;
}

.text-comment {
  font-size: 10px;
  @media (min-width: $screen-tablet) {
    font-size: 12px;
  }
  color: $grey;
}

.product-quantity {
  .form-control {
    max-width:70px !important;
    margin-bottom:8px;
  }
}

/* Fade In */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}