.form-card {
  &.login {
    display: flex;
    flex-direction: column;
    @media all and (min-width: $screen-tablet) {
      flex-direction: row;
      & > div {
        flex: 1 0 50%;
      }
    }
  }
}

.register-form-phone {

  input {
    display:inline-block;
    min-width:130px;
    max-width:200px;
    margin-bottom:5px;
  }

  input.small {
    width:100px;
    min-width:100px;
    max-width:100px;
  }
}

.register-form-info {
  color:#888;
  font-style:italic;
  font-size:11px;
  margin-bottom:45px;
}

.alert-demo-account {
  color:white;
  margin:20px;
  padding:20px;
  background:palevioletred;
  font-size:15px;
  border:3px solid red;
  font-weight:bold;
}

.account-page {
  background-color: $white-three;
  padding-bottom:50px;

  &.form {
    h3, h4, strong {
      color: $violet-dark;
    }
    .radio-container {
      font-size:10px;
      margin-top: 15px;
      display: flex;
      align-items: center;

      input[type="checkbox"] {
        margin-right:7px;
      }
    }
  }

  h1 {
    font-size:42px;
    color: $violet-dark;
    text-align: center;
    margin:0;
    padding:60px 0 25px 0;
    word-break: break-word;
  }

  .card {
    @include card();
    background: white;
    padding:25px;
  }

  .data-tabs {
    margin-bottom:-6px;
    padding-bottom:6px;

    a {
      display:inline-block;
      text-decoration: none;
      font-size: 24px;
      color: $slate-grey;
      font-weight: bold;
      padding: 10px 20px;
    }
    a.active {
      background:white;
      color: $violet-dark;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .data-card {
    @include card();
    background: white;
    padding:22px 20px 26px 20px;

    &.em-back {
      background:$violet-bright;
    }

    h3 {
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      border-bottom:2px solid $white-three;
      margin:-22px -20px 19px -20px;
      padding:22px 20px 14px 20px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background: white;
    }
    .em {
      font-size: 24px;
      line-height: 29px;
      color: $violet-dark;
      font-weight: bold;
    }
  }

  .form-center {
    margin:0 auto;
    max-width:590px;
  }

  .max-center {
    margin:0 auto;
    max-width:1000px;
    padding:0 20px;
  }

  .info {
    padding:40px 20px 60px 20px;

    b {
      display:block;
      text-transform: uppercase;
      letter-spacing: 1.2px;
    }
  }

  @media (max-width: $screen-phone-max) {
    min-height: calc(100vh - 301px);
  }
  @media (min-width: $screen-tablet) and (max-width: $screen-tablet-max) {
    min-height: calc(100vh - 356px);
  }
  @media (min-width: $screen-notebook) {
    min-height: calc(100vh - 263px);
  }
}
